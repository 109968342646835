@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('ttf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

p {
  line-height: 150%;
  color: #070042;
}

.cookie-consent-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.cookie-consent-buttons {
  margin: auto;
  width: 100%;
}

.cookie-consent-content {
  flex: unset !important;
}

.swiper .favouriteProductsCarousel {
  width: 100%;
  height: 100%;
}

.favouriteProductsCarousel > * .swiper-slide {
  text-align: center;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favouriteProductsCarousel > * .swiper-slide .background {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 992px) {
  .favouriteProductsCarousel > * .swiper-slide {
    width: 90%;
  }
}

@media only screen and (max-width: 992px) {
  .favouriteProductsCarousel > * .swiper-slide .background {
    width: 19.375rem;
    height: 43.75rem;
    border-radius: 1rem;
  }
}

.ant-rate-star-full > * .anticon-star {
  color: #70bff9;
}

.contentCardsCarousel > .swiper-pagination-bullets {
  display: none;
}

@media only screen and (max-width: 992px) {
  .contentCardsCarousel > * .swiper-slide {
    padding: 0 1.25rem;
  }
}
